import React, { MouseEventHandler } from "react";
import { Group } from "../features/compliance/models/group";
import { Worker } from "../features/compliance/models/worker";
import { AccreditationSpec } from "../features/common/models/accreditationSpec";
import { Role } from "../features/common/models/role";

export type ButtonProps = {
  label: string;
  onClick: MouseEventHandler;
};

type PageHeaderContextProps = {
  group?: Group;
  setGroup?: React.Dispatch<React.SetStateAction<Group | undefined>>;
  worker?: Worker;
  setWorker?: React.Dispatch<React.SetStateAction<Worker | undefined>>;
  buttons?: ButtonProps[];
  setButtons?: React.Dispatch<React.SetStateAction<ButtonProps[] | undefined>>;
  accreditationSpec?: AccreditationSpec;
  setAccreditationSpec?: React.Dispatch<
    React.SetStateAction<AccreditationSpec | undefined>
  >;
  role?: Role;
  setRole?: React.Dispatch<React.SetStateAction<Role | undefined>>;
};

export const PageHeaderContext = React.createContext<PageHeaderContextProps>(
  {}
);
