import { Navigate, RouteObject } from "react-router-dom";
import VisiblyOSLayout from "../components/layout/VisiblyOSLayout";
import { lazy } from "react";
import UserGuard from "../components/guards/UserGuard";

const Members = lazy(() => import("../features/company/pages/Members"));

const RolePermissions = lazy(
  () => import("../features/company/pages/RolePermissions")
);

export const companyRoutes = [
  {
    path: "company",
    element: (
      <UserGuard>
        <VisiblyOSLayout />
      </UserGuard>
    ),
    children: [
      {
        path: "",
        element: <Navigate to="/company/members" />,
      },
      {
        path: "members",
        element: <Members />,
      },
      {
        path: "role-permissions",
        element: <RolePermissions />,
      },
    ],
  },
] as RouteObject[];
