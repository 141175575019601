import {
  Button,
  ButtonGroup,
  Grid,
  Grow,
  MenuList,
  Paper,
  Popper,
  Typography,
} from "@mui/material";
import { ReactNode, useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet-async";
import { InnerNavigationItem } from "../../types/sidebar";
import BackButton from "../../features/compliance/components/BackButton";
import { ChevronDown } from "react-feather";
import { ButtonProps } from "../../contexts/PageHeaderContext";
import InnerNavigationBar from "../InnerNavigationBar";

export type PageWrapperProps = {
  children: ReactNode;
  title?: string;
  innerNavigationItems?: InnerNavigationItem[];
  hasBackButton?: boolean;
  backButtonTo?: string;
  buttons?: ButtonProps[];
};

function PageWrapper(props: PageWrapperProps) {
  const anchorRef = useRef<HTMLDivElement>(null);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setOpen(false);
  }, [props]);

  return (
    <>
      <Helmet />
      <Grid container spacing={6}>
        <Grid item xs={9}>
          {props.hasBackButton && props.backButtonTo && (
            <BackButton to={props.backButtonTo} />
          )}
          {props.title && <Typography variant="h2">{props.title}</Typography>}
        </Grid>
        {props.buttons && props.buttons.length > 0 && (
          <Grid
            item
            xs={3}
            style={{ display: "flex", justifyContent: "flex-end" }}
          >
            <ButtonGroup variant="contained" ref={anchorRef}>
              <Button onClick={props.buttons[0].onClick}>
                {props.buttons[0].label}
              </Button>
              {props.buttons.length > 1 && (
                <Button
                  size="small"
                  onClick={() => {
                    setOpen(!open);
                  }}
                >
                  <ChevronDown />
                </Button>
              )}
            </ButtonGroup>
            {anchorRef.current && (
              <Popper
                id={`buttons-popper`}
                sx={{ zIndex: 1 }}
                open={open}
                anchorEl={anchorRef.current}
                transition
                disablePortal
                placement="bottom-end"
              >
                {({ TransitionProps, placement }) => (
                  <Grow {...TransitionProps}>
                    <Paper>
                      <MenuList
                        id="split-button-menu"
                        autoFocusItem
                        style={{ padding: 0 }}
                      >
                        {props.buttons?.map((option, index) => {
                          if (index === 0) return null;
                          return (
                            <Button
                              key={option.label}
                              onClick={option.onClick}
                              variant="contained"
                            >
                              {option.label}
                            </Button>
                          );
                        })}
                      </MenuList>
                    </Paper>
                  </Grow>
                )}
              </Popper>
            )}
          </Grid>
        )}
        <InnerNavigationBar items={props.innerNavigationItems} />
        {props.children}
      </Grid>
    </>
  );
}

export default PageWrapper;
