import { AxiosPromise } from "axios";
import axios from "../../../utils/axios";
import { ApiResponseMeta } from "../models/apiResponseMeta";
import {
  QueryAccreditationsRequest,
  UpdateExpiryDateRequest,
} from "../slices/accreditationSlice";
import { AccreditationWithProofs } from "../../compliance/models/accreditation";

type BulkImportRequest = {
  csvImportId: string;
  orgId: string;
};

type BulkImportResponse = {
  meta: ApiResponseMeta;
};

export async function importAccreditations(
  request: BulkImportRequest
): Promise<AxiosPromise<BulkImportResponse>> {
  return await axios.post(`accreditations/v1/import-from-csv`, request);
}

type QueryAccreditationsResponse = {
  meta: ApiResponseMeta;
  data: AccreditationWithProofs[];
};

export async function queryAccreditations(
  request: QueryAccreditationsRequest
): Promise<AxiosPromise<QueryAccreditationsResponse>> {
  return await axios.post(`accreditations/v1/query`, request);
}

type GetAccreditationResponse = {
  meta: ApiResponseMeta;
  data: AccreditationWithProofs;
};

export async function getAccreditation(
  id: string
): Promise<AxiosPromise<GetAccreditationResponse>> {
  return await axios.get(`accreditations/v1/${id}`);
}

type SubmitProofsResponse = {
  meta: ApiResponseMeta;
};

type SubmitProofsRequest = {
  orgId: string;
  proofs: { type: "photo" | "pdf"; createdAt: string; mediaKey: string }[];
};

export async function submitProofs(
  id: string,
  request: SubmitProofsRequest
): Promise<AxiosPromise<SubmitProofsResponse>> {
  return await axios.post(
    `accreditations/v1/${id}/admin-submit-proof`,
    request
  );
}

type RemoveProofResponse = {
  meta: ApiResponseMeta;
};

export async function removeProof(
  accreditationId: string,
  proofId: string
): Promise<AxiosPromise<RemoveProofResponse>> {
  return await axios.delete(
    `accreditations/v1/${accreditationId}/proofs/${proofId}`
  );
}

type MarkProofAsSeenRequest = {
  orgId: string;
  userId: string;
};

export async function markProofAsSeen(
  accreditationId: string,
  proofId: string,
  request: MarkProofAsSeenRequest
): Promise<AxiosPromise<void>> {
  return await axios.post(
    `accreditations/v1/${accreditationId}/proofs/${proofId}/approvals`,
    request
  );
}

export async function undoMarkProofAsSeen(
  accreditationId: string,
  proofId: string,
  approvalId: string
): Promise<AxiosPromise<void>> {
  return await axios.delete(
    `accreditations/v1/${accreditationId}/proofs/${proofId}/approvals/${approvalId}`
  );
}

type CreateAccreditationResponse = {
  meta: ApiResponseMeta;
};

type CreateAccreditationRequest = {
  userId: string;
  accreditationSpecId: number;
  expiresAt: string;
  orgId: string;
};

export async function createAccreditation(
  request: CreateAccreditationRequest
): Promise<AxiosPromise<CreateAccreditationResponse>> {
  return await axios.post(`accreditations/v1`, request);
}

type UpdateExpiryDateResponse = {
  meta: ApiResponseMeta;
};

export async function updateExpiryDate(
  id: string,
  request: UpdateExpiryDateRequest
): Promise<AxiosPromise<UpdateExpiryDateResponse>> {
  return await axios.put(`accreditations/v1/${id}/expiry-date`, request);
}
