import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  queryAssigned as arsQueryAssigned,
  queryRoles as arsQueryRoles,
  getRole as arsGetRole,
} from "../services/accreditationRolesService";
import { LoadingStatus } from "../models/loadingStatus";
import { AssignedRole } from "../../compliance/models/assignedRoles";
import { Role } from "../models/role";

export type QueryAssignedRequest = {
  groupId?: string;
  orgId: string;
  userId?: string;
  roleIds?: string[];
};

export const queryAssigned = createAsyncThunk(
  "accreditationRoles/queryAssignedRoles",
  async (request: QueryAssignedRequest, { rejectWithValue }) => {
    try {
      return (await arsQueryAssigned(request)).data;
    } catch (err) {
      return rejectWithValue({
        error: "An error occurred",
      });
    }
  }
);

export type QueryRolesRequest = {
  orgId: string;
};

export const queryRoles = createAsyncThunk(
  "roles/query",
  async (request: QueryRolesRequest, { rejectWithValue }) => {
    try {
      return (await arsQueryRoles(request)).data;
    } catch (err) {
      return rejectWithValue({
        error: "An error occurred",
      });
    }
  }
);

export const getRole = createAsyncThunk(
  "roles/get",
  async (id: string, { rejectWithValue }) => {
    try {
      return (await arsGetRole(id)).data;
    } catch (err) {
      return rejectWithValue({
        error: "An error occurred",
      });
    }
  }
);

type AccreditationRolesState = {
  status: LoadingStatus;
  assignedRoles: AssignedRole[];
  roles: Role[];
  role?: Role;
  error: string | null;
};

const initialState: AccreditationRolesState = {
  status: LoadingStatus.idle,
  assignedRoles: [],
  roles: [],
  error: null,
};

const accreditationRolesSlice = createSlice({
  name: "accreditationRoles",
  initialState,
  reducers: {
    cleanState: () => {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(queryAssigned.pending, (state, action) => {
      return { ...state, status: LoadingStatus.loading };
    });
    builder.addCase(queryAssigned.fulfilled, (state, action) => {
      return {
        ...state,
        status: LoadingStatus.succeeded,
        assignedRoles: action.payload.data,
      };
    });
    builder.addCase(queryAssigned.rejected, (state, action) => {
      return {
        ...state,
        status: LoadingStatus.failed,
      };
    });
    builder.addCase(queryRoles.pending, (state, action) => {
      return { ...state, status: LoadingStatus.loading };
    });
    builder.addCase(queryRoles.fulfilled, (state, action) => {
      return {
        ...state,
        status: LoadingStatus.succeeded,
        roles: action.payload.data,
      };
    });
    builder.addCase(queryRoles.rejected, (state, action) => {
      return {
        ...state,
        status: LoadingStatus.failed,
      };
    });
    builder.addCase(getRole.pending, (state, action) => {
      return { ...state, status: LoadingStatus.loading };
    });
    builder.addCase(getRole.fulfilled, (state, action) => {
      return {
        ...state,
        status: LoadingStatus.succeeded,
        role: action.payload.data,
      };
    });
    builder.addCase(getRole.rejected, (state, action) => {
      return {
        ...state,
        status: LoadingStatus.failed,
      };
    });
  },
});

export const { cleanState } = accreditationRolesSlice.actions;
export default accreditationRolesSlice.reducer;
