import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import * as groupMembersService from "../services/groupMembersService";
import { LoadingStatus } from "../../common/models/loadingStatus";
import { GroupMember } from "../features/groups/models/groupMembers";

export type QueryGroupMembersRequest = {
  orgId: string;
};

export const queryGroupMembers = createAsyncThunk(
  "groups/query-members",
  async (
    { groupId, ...request }: QueryGroupMembersRequest & { groupId: string },
    { rejectWithValue }
  ) => {
    try {
      return (await groupMembersService.queryGroupMembers(groupId, request))
        .data;
    } catch (err: any) {
      return rejectWithValue({
        error: err.meta.errorMessage ?? "An error occurred",
      });
    }
  }
);

export const getGroupMember = createAsyncThunk(
  "groups/get-member",
  async (
    { groupId, userId }: { groupId: string; userId: string },
    { rejectWithValue }
  ) => {
    try {
      return (await groupMembersService.getGroupMember(groupId, userId)).data;
    } catch (err: any) {
      return rejectWithValue({
        error: err.meta.errorMessage ?? "An error occurred",
      });
    }
  }
);

type GroupMemberState = {
  status: LoadingStatus;
  groupMembers: GroupMember[];
  groupMember?: GroupMember;
  error?: string;
};

const initialState: GroupMemberState = {
  status: LoadingStatus.idle,
  groupMembers: [],
};

const groupMembersSlice = createSlice({
  name: "groupMembers",
  initialState,
  reducers: {
    cleanState: () => {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(queryGroupMembers.pending, (state, action) => {
      return { ...state, status: LoadingStatus.loading };
    });
    builder.addCase(queryGroupMembers.fulfilled, (state, action) => {
      return {
        ...state,
        status: LoadingStatus.succeeded,
        groupMembers: action.payload.data,
      };
    });
    builder.addCase(queryGroupMembers.rejected, (state, action) => {
      return {
        ...state,
        status: LoadingStatus.failed,
      };
    });
    builder.addCase(getGroupMember.pending, (state, action) => {
      return { ...state, status: LoadingStatus.loading };
    });
    builder.addCase(getGroupMember.fulfilled, (state, action) => {
      return {
        ...state,
        status: LoadingStatus.succeeded,
        groupMember: action.payload.data,
      };
    });
    builder.addCase(getGroupMember.rejected, (state, action) => {
      return {
        ...state,
        status: LoadingStatus.failed,
      };
    });
  },
});

export const { cleanState } = groupMembersSlice.actions;
export default groupMembersSlice.reducer;
