import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { LoadingStatus } from "../models/loadingStatus";
import * as permissionsService from "../services/permissionsService";
import { DirectWorkforcePermission } from "../models/permissions";

export const getAll = createAsyncThunk(
  "permissions/get-all",
  async (orgId: string, { rejectWithValue }) => {
    try {
      return (await permissionsService.getAll(orgId)).data;
    } catch (err) {
      return rejectWithValue({ error: err });
    }
  }
);

interface PermissionsState {
  directWorkforcePermissions: DirectWorkforcePermission[];
  status: LoadingStatus;
  error: string;
}

const initialState: PermissionsState = {
  directWorkforcePermissions: [],
  status: LoadingStatus.idle,
  error: "",
};

const permissionsSlice = createSlice({
  name: "permissions",
  initialState,
  reducers: {
    cleanState: () => {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAll.pending, (state, action) => {
      return { ...state, status: LoadingStatus.loading };
    });
    builder.addCase(getAll.fulfilled, (state, action) => {
      return {
        ...state,
        status: LoadingStatus.succeeded,
        directWorkforcePermissions: action.payload.data,
      };
    });
    builder.addCase(getAll.rejected, (state, action) => {
      return { ...state, status: LoadingStatus.failed, teams: [] };
    });
  },
});

export const { cleanState } = permissionsSlice.actions;

export default permissionsSlice.reducer;
