import { UserOrganisation } from "../features/common/models/user";

export const GetHomePagePath = (
  isAuthenticated: boolean,
  currentOrg?: UserOrganisation
) => {
  if (!isAuthenticated) {
    return "/auth/sign-in";
  }
  if (currentOrg?.capabilities && currentOrg.capabilities.length > 0) {
    if (currentOrg?.capabilities.includes("checkIns")) {
      return "/check-ins/dashboard";
    }
    if (currentOrg?.capabilities.includes("accreditations")) {
      if (currentOrg.directWorkforcePermissions?.canView) {
        return "/compliance/workforce";
      } else {
        return "/compliance/groups";
      }
    }
  }
  return "/download-app";
};
