import { AxiosPromise } from "axios";
import axios from "../../../utils/axios";
import { AccreditationWithProofs } from "../../compliance/models/accreditation";
import { ApiResponseMeta } from "../models/apiResponseMeta";
import { QueryAccreditationsRequest } from "../slices/accreditationSlice";

export type QueryGroupAccreditationsResponse = {
  data: AccreditationWithProofs[];
  meta: ApiResponseMeta;
};

export async function queryGroupAccreditations(
  groupId: string,
  request: QueryAccreditationsRequest
): Promise<AxiosPromise<QueryGroupAccreditationsResponse>> {
  return await axios.post(
    `compliance/groups/v1/${groupId}/accreditations/query`,
    request
  );
}

export type GetGroupAccreditationResponse = {
  data: AccreditationWithProofs;
  meta: ApiResponseMeta;
};

export async function getGroupAccreditation(
  groupId: string,
  accreditationId: string
): Promise<AxiosPromise<GetGroupAccreditationResponse>> {
  return await axios.get(
    `compliance/groups/v1/${groupId}/accreditations/${accreditationId}`
  );
}
