import { Outlet } from "react-router-dom";
import { useDirectWorkforcePermissions } from "../../hooks/userHooks";
import NavigateToUserHome from "../NavigateToUserHome";

function DirectWorkforceRouteGuard() {
  const { canView } = useDirectWorkforcePermissions();
  if (!canView) {
    return <NavigateToUserHome />;
  }

  return <Outlet />;
}

export default DirectWorkforceRouteGuard;
