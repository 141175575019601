import { Warning } from "@mui/icons-material";
import { Box, Grid, Typography } from "@mui/material";

type ErrorComponentProps = {
  text?: string;
};

function ErrorComponent(props: ErrorComponentProps) {
  return (
    <Grid container>
      <Grid item xs={12}>
        <Box justifyContent={"center"} display={"flex"}>
          <Box
            alignItems={"center"}
            display={"flex"}
            position={"absolute"}
            top={"calc(50% - 2rem)"}
          >
            <Warning
              color="error"
              fontSize="large"
              style={{ float: "left", marginRight: 10 }}
            />
            <Typography variant="body2" color="error" textAlign="center">
              {props.text ?? "An error has occurred"}
            </Typography>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
}

export default ErrorComponent;
