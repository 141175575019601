import { CheckCircle } from "react-feather";
import { SidebarItemsType } from "../types/sidebar";

export const companySection = [
  {
    href: "/company",
    icon: CheckCircle,
    pageTitle: () => "Company",
    innerNavigationItems: [
      {
        title: "Members",
        to: (_) => "/company/members",
      },
      {
        title: "Role permissions",
        to: (_) => "/company/role-permissions",
      },
    ],
  },
] as SidebarItemsType[];
