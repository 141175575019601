import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { Enrolment, EnrolmentState } from "../models/enrolment";

import * as learningEnrolmentService from "../services/learningEnrolmentService";
import { EnrolmentStructure } from "../models/courseUnit";
import { LoadingStatus } from "../../common/models/loadingStatus";

export type QueryEnrolmentRequest = {
  orgId: string;
  userId?: string;
  courseId?: string;
  states?: EnrolmentState[];
};

export const queryEnrolments = createAsyncThunk(
  "learning-enrolment/queryEnrolments",
  async (request: QueryEnrolmentRequest, { rejectWithValue }) => {
    try {
      return (await learningEnrolmentService.queryEnrolmentsAsync(request))
        .data;
    } catch (err) {
      console.warn(err);
      return rejectWithValue({ error: err });
    }
  }
);

export const getEnrolment = createAsyncThunk(
  "learning-enrolment/getEnrolment",
  async (id: string, { rejectWithValue }) => {
    try {
      return (await learningEnrolmentService.getEnrolmentAsync(id)).data;
    } catch (err) {
      console.warn(err);
      return rejectWithValue({ error: err });
    }
  }
);

export const getEnrolmentStructure = createAsyncThunk(
  "learning-courses/enrolmentStructure",
  async (enrolmentId: string, { rejectWithValue }) => {
    try {
      return (
        await learningEnrolmentService.getEnrolmentStructureAsync(enrolmentId)
      ).data;
    } catch (err) {
      console.warn(err);
      return rejectWithValue({ error: err });
    }
  }
);

interface LearningEnrolmentState {
  enrolment?: Enrolment;
  enrolments: Enrolment[];
  structure?: EnrolmentStructure;
  status: LoadingStatus;
  structureStatus: LoadingStatus;
  error: string;
}

const initialState: LearningEnrolmentState = {
  enrolments: [],
  status: LoadingStatus.idle,
  structureStatus: LoadingStatus.idle,
  error: "",
};

const learningEnrolmentsSlice = createSlice({
  name: "learningEnrolments",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(queryEnrolments.pending, (state) => {
      return {
        ...state,
        status: LoadingStatus.loading,
      };
    });
    builder.addCase(queryEnrolments.fulfilled, (state, action) => {
      return {
        ...state,
        status: LoadingStatus.succeeded,
        enrolments: action.payload.data,
      };
    });
    builder.addCase(queryEnrolments.rejected, (state) => {
      // @ts-ignore
      return {
        ...state,
        status: LoadingStatus.failed,
        enrolments: [],
        error: "Failed to query enrolments",
      };
    });

    builder.addCase(getEnrolment.pending, (state) => {
      return {
        ...state,
        status: LoadingStatus.loading,
      };
    });
    builder.addCase(getEnrolment.fulfilled, (state, action) => {
      return {
        ...state,
        status: LoadingStatus.succeeded,
        enrolment: action.payload.data,
      };
    });
    builder.addCase(getEnrolment.rejected, (state) => {
      // @ts-ignore
      return {
        ...state,
        status: LoadingStatus.failed,
        error: "Failed to get enrolment",
      };
    });

    builder.addCase(getEnrolmentStructure.pending, (state) => {
      return {
        ...state,
        structureStatus: LoadingStatus.loading,
      };
    });
    builder.addCase(getEnrolmentStructure.fulfilled, (state, action) => {
      return {
        ...state,
        structureStatus: LoadingStatus.succeeded,
        structure: action.payload.data,
      };
    });
    builder.addCase(getEnrolmentStructure.rejected, (state) => {
      // @ts-ignore
      return {
        ...state,
        structureStatus: LoadingStatus.failed,
        error: "Failed to get course contents",
      };
    });
  },
});

export default learningEnrolmentsSlice.reducer;
