import { Navigate, RouteObject, useParams } from "react-router-dom";
import VisiblyOSLayout from "../components/layout/VisiblyOSLayout";
import { lazy, ReactElement } from "react";
import { useContext, useEffect } from "react";
import useAppDispatch from "../hooks/useAppDispatch";
import { PageHeaderContext } from "../contexts/PageHeaderContext";
import { getGroup } from "../features/compliance/features/groups/slices/groupsSlice";
import CanViewCapabilityRouteGuard from "../components/guards/CanViewCapabilityRouteGuard";
import { getGroupMember } from "../features/compliance/slices/groupMembersSlice";

const Groups = lazy(
  () => import("../features/compliance/features/groups/pages/groups")
);

const Dashboard = lazy(
  () => import("../features/compliance/features/groups/pages/groups/Dashboard")
);

const GroupRoles = lazy(
  () =>
    import(
      "../features/compliance/features/groups/pages/group/groupRoles/index"
    )
);

const WorkerRoles = lazy(
  () => import("../features/compliance/features/groups/pages/group/WorkerRoles")
);

const Workers = lazy(
  () => import("../features/compliance/features/groups/pages/group/Workers")
);

const Accreditations = lazy(
  () =>
    import("../features/compliance/features/groups/pages/group/Accreditations")
);

const WorkerDetails = lazy(
  () => import("../features/compliance/components/workerDetails")
);

const WorkerWorkerRoles = lazy(
  () => import("../features/compliance/components/workerDetails/WorkerRoles")
);

const WorkerAccreditations = lazy(
  () => import("../features/compliance/components/workerDetails/Accreditations")
);

export const groupsRoutes = [
  {
    path: "compliance",
    element: (
      <CanViewCapabilityRouteGuard capability="accreditations">
        <VisiblyOSLayout />
      </CanViewCapabilityRouteGuard>
    ),
    children: [
      {
        path: "groups",
        children: [
          {
            path: "",
            element: <Navigate to="/compliance/groups/dashboard" />,
          },
          {
            path: "dashboard",
            element: <Dashboard />,
          },
          {
            path: "list",
            element: <Groups />,
          },
        ],
      },
      {
        path: "groups/:groupId",
        children: [
          {
            path: "",
            element: <NavigateToGroupHome />,
          },
          {
            path: "group-roles",
            element: (
              <GroupWrapper>
                <GroupRoles />
              </GroupWrapper>
            ),
          },
          {
            path: "worker-roles",
            element: (
              <GroupWrapper>
                <WorkerRoles />
              </GroupWrapper>
            ),
          },
          {
            path: "workers",
            children: [
              {
                path: "",
                element: <Workers />,
              },
              {
                path: ":workerId",
                children: [
                  {
                    path: "",
                    element: <NavigateToGroupWorkerHome />,
                  },
                  {
                    path: "details",
                    element: (
                      <GroupWorkerWrapper>
                        <WorkerDetails />
                      </GroupWorkerWrapper>
                    ),
                  },
                  {
                    path: "worker-roles",
                    element: (
                      <GroupWorkerWrapper>
                        <WorkerWorkerRoles />
                      </GroupWorkerWrapper>
                    ),
                  },
                  {
                    path: "accreditations",
                    element: (
                      <GroupWorkerWrapper>
                        <WorkerAccreditations />
                      </GroupWorkerWrapper>
                    ),
                  },
                ],
              },
            ],
          },
          {
            path: "accreditations",
            element: (
              <GroupWrapper>
                <Accreditations />
              </GroupWrapper>
            ),
          },
        ],
      },
    ],
  },
] as RouteObject[];

type GroupWrapperProps = {
  children: ReactElement;
};

function GroupWrapper(props: GroupWrapperProps) {
  const dispatch = useAppDispatch();
  const { groupId } = useParams();
  const { group, setGroup } = useContext(PageHeaderContext);

  useEffect(() => {
    if (groupId && (!group || groupId !== group.id)) {
      dispatch(getGroup(groupId))
        .unwrap()
        .then((value) => {
          setGroup!(value.data);
        });
    }
  }, [group, groupId, setGroup, dispatch]);

  return <>{group && groupId === group.id ? props.children : null}</>;
}

function NavigateToGroupHome() {
  const { groupId } = useParams();

  return <Navigate to={`/compliance/groups/${groupId}/group-roles`} />;
}

function GroupWorkerWrapper(props: GroupWrapperProps) {
  const dispatch = useAppDispatch();
  const { groupId, workerId } = useParams();
  const { group, setGroup, worker, setWorker } = useContext(PageHeaderContext);

  useEffect(() => {
    if (groupId && (!group || groupId !== group.id)) {
      dispatch(getGroup(groupId))
        .unwrap()
        .then((value) => {
          setGroup!(value.data);
        });
    }
  }, [group, groupId, setGroup, dispatch]);

  useEffect(() => {
    if (workerId && groupId && (!worker || workerId !== worker.id)) {
      dispatch(getGroupMember({ groupId: groupId, userId: workerId }))
        .unwrap()
        .then((value) => {
          setWorker!({
            id: value.data.userId,
            firstNames: value.data.firstNames,
            lastName: value.data.lastName,
            phoneNumber: value.data.phoneNumber,
            email: value.data.inOrgEmail,
            postcode: value.data.postcode,
            dateOfBirth: value.data.dateOfBirth,
            signedUp: value.data.signedUp,
            orgId: value.data.orgId,
          });
        });
    }
  }, [groupId, dispatch, worker, workerId, setWorker]);

  return (
    <>
      {group && groupId === group.id && worker && workerId === worker.id
        ? props.children
        : null}
    </>
  );
}

function NavigateToGroupWorkerHome() {
  const { groupId, workerId } = useParams();

  return (
    <Navigate
      to={`/compliance/groups/${groupId}/workers/${workerId}/details`}
    />
  );
}
