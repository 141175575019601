import { Users } from "react-feather";
import { SidebarItemsType } from "../../types/sidebar";
import DirectWorkforceViewGuard from "../../components/sidebar/navItemGuards/DirectWorkforceViewGuard";

export const workforceItem = {
  href: "/compliance/workforce",
  icon: Users,
  pageTitle: () => "Direct Workforce",
  title: "Workforce",
  wrapper: "directWorkforce",
  innerNavigationItems: [
    {
      title: "Dashboard",
      to: (_) => "/compliance/workforce/dashboard",
    },
    {
      title: "Group roles",
      to: (_) => "/compliance/workforce/group-roles",
    },
    {
      title: "Workers",
      to: (_) => "/compliance/workforce/workers",
    },
    {
      title: "Worker roles",
      to: (_) => "/compliance/workforce/worker-roles",
    },
    {
      title: "Accreditations",
      to: (_) => "/compliance/workforce/accreditations",
    },
  ],
  children: [
    {
      pageTitle: (workerName: string) => `${workerName ?? ""}`,
      hasBackButton: true,
      backButtonTo: (_) => `/compliance/workforce/workers`,
      type: "worker",
      innerNavigationItems: [
        {
          title: "Details",
          to: (_) => `/compliance/workforce/workers/${_.workerId}/details`,
        },
        {
          title: "Worker roles",
          to: (_) => `/compliance/workforce/workers/${_.workerId}/worker-roles`,
        },
        {
          title: "Accreditations",
          to: (_) =>
            `/compliance/workforce/workers/${_.workerId}/accreditations`,
        },
      ],
    },
  ],
  wrapperElement: (key: string, children: JSX.Element) => (
    <DirectWorkforceViewGuard key={key}>{children}</DirectWorkforceViewGuard>
  ),
} as SidebarItemsType;
