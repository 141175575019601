import { getCurrentUser } from "aws-amplify/auth";
import { fetchAuthSession } from "aws-amplify/auth";
import { visiblyApiConfig } from "../config";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import * as Sentry from "@sentry/browser";
import { getCurrentOrgId } from "../contexts/AmplifyContext";

const instance = axios.create({
  baseURL: visiblyApiConfig.baseUrl,
});

const checkForCurrentUser = async () => {
  try {
    const { username } = await getCurrentUser();
    return username !== null;
  } catch (error: any) {
    if (error.name === "UserUnAuthenticatedException") {
      console.warn("User is not authenticated.");
      return false;
    }

    console.error("Error checking for current user:", error);
    return false;
  }
};

instance.interceptors.request.use(
  async (config) => {
    if (await checkForCurrentUser()) {
      const { idToken } = (await fetchAuthSession()).tokens ?? {};

      const token = idToken!.toString();
      if (token) {
        if (config.headers) {
          config.headers.Authorization = `Bearer ${token}`;
        } else {
          config.headers = {
            Authorization: `Bearer ${token}`,
          };
        }
      }

      const currentOrgId = getCurrentOrgId();
      if (currentOrgId) {
        if (config.headers) {
          config.headers["visibly-org-id"] = currentOrgId;
        } else {
          config.headers = {
            "visibly-org-id": currentOrgId,
          };
        }
      }

      const correlationId = uuidv4();
      Sentry.configureScope((scope) => {
        scope.setTag("visibly-correlation-id", correlationId);
      });
      if (config.headers) {
        config.headers["visibly-correlation-id"] = correlationId;
      } else {
        config.headers = {
          "visibly-correlation-id": correlationId,
        };
      }
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  async (response) => {
    Sentry.configureScope((scope) => {
      scope.setTag("visibly-correlation-id", undefined);
    });
    return response;
  },
  (error) => {
    Sentry.captureException(error);
    Sentry.configureScope((scope) => {
      scope.setTag("visibly-correlation-id", undefined);
    });
    return Promise.reject(error);
  }
);

export default instance;
