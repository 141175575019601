import { AxiosPromise } from "axios";
import axios from "../../../utils/axios";
import { QueryPracticalChallengesRequest } from "../slices/learningPracticalChallengesSlice";
import {
  PracticalChallengeForList,
  PracticalChallengeForReview,
} from "../models/practicalChallenges";
import { ApiResponseMeta } from "../../common/models/apiResponseMeta";

type QueryPracticalChallengesResponse = {
  data: PracticalChallengeForList[];
  meta: ApiResponseMeta;
};

async function queryPracticalChallengesAsync(
  request: QueryPracticalChallengesRequest
): Promise<AxiosPromise<QueryPracticalChallengesResponse>> {
  return await axios.post(`/learning/v2/practical-challenges/query`, request);
}

type GetPracticalChallengeForReviewResponse = {
  data: PracticalChallengeForReview;
  meta: ApiResponseMeta;
};

async function getPracticalChallengeForReviewAsync(
  practicalChallengeId: string
): Promise<AxiosPromise<GetPracticalChallengeForReviewResponse>> {
  return await axios.get(
    `/learning/v2/practical-challenges/${practicalChallengeId}/for-reviewer`
  );
}

type SubmitReviewRequest = {
  score: number;
  comment?: string;
};

async function submitReviewAsync(
  practicalChallengeId: string,
  request: SubmitReviewRequest
): Promise<AxiosPromise> {
  return await axios.post(
    `/learning/v2/practical-challenges/${practicalChallengeId}/submit-review`,
    request
  );
}

export {
  queryPracticalChallengesAsync,
  getPracticalChallengeForReviewAsync,
  submitReviewAsync,
};
