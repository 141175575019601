import { AxiosPromise } from "axios";
import axios from "../../../utils/axios";
import { ApiResponseMeta } from "../../common/models/apiResponseMeta";
import { GroupMember } from "../features/groups/models/groupMembers";
import { QueryGroupMembersRequest } from "../slices/groupMembersSlice";

type QueryGroupMembersResponse = {
  data: GroupMember[];
  meta: ApiResponseMeta;
};

export async function queryGroupMembers(
  groupId: string,
  request: QueryGroupMembersRequest
): Promise<AxiosPromise<QueryGroupMembersResponse>> {
  return await axios.post(
    `compliance/groups/v1/${groupId}/members/query`,
    request
  );
}

type GetGroupMemberResponse = {
  data: GroupMember;
  meta: ApiResponseMeta;
};

export async function getGroupMember(
  groupId: string,
  userId: string
): Promise<AxiosPromise<GetGroupMemberResponse>> {
  return await axios.get(`compliance/groups/v1/${groupId}/members/${userId}`);
}
