import { AxiosPromise } from "axios";
import { ApiResponseMeta } from "../models/apiResponseMeta";
import { AssignedRole } from "../../compliance/models/assignedRoles";
import axios from "../../../utils/axios";
import { Role } from "../models/role";
import {
  QueryAssignedRequest,
  QueryRolesRequest,
} from "../slices/accreditationRolesSlice";

type QueryAssignedResponse = {
  data: AssignedRole[];
  meta: ApiResponseMeta;
};

export async function queryAssigned(
  request: QueryAssignedRequest
): Promise<AxiosPromise<QueryAssignedResponse>> {
  return await axios.post(`accreditations/roles/v1/query-assigned`, request);
}

type QueryRolesResponse = {
  meta: ApiResponseMeta;
  data: Role[];
};

export type CreateRoleRequest = {
  orgId: string;
  name: string;
  visibility: string;
  mandatoryAccreditations: number[];
  interchangeableAccreditations?: number[][];
};

export async function queryRoles(
  request: QueryRolesRequest
): Promise<AxiosPromise<QueryRolesResponse>> {
  return await axios.post(`accreditations/roles/v1/query`, request);
}

type GetRoleResponse = {
  meta: ApiResponseMeta;
  data: Role;
};

export async function getRole(
  id: string
): Promise<AxiosPromise<GetRoleResponse>> {
  return await axios.get(`accreditations/roles/v1/${id}`);
}

export async function createRole(
  request: CreateRoleRequest
): Promise<AxiosPromise> {
  return await axios.post(`accreditations/roles/v1`, request);
}

export async function updateRole(
  roleId: string,
  request: CreateRoleRequest
): Promise<AxiosPromise> {
  return await axios.put(`accreditations/roles/v1/${roleId}`, request);
}
