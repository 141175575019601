import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  queryPracticalChallengesAsync,
  getPracticalChallengeForReviewAsync,
} from "../services/learningPracticalChallengesService";
import {
  PracticalChallengeForList,
  PracticalChallengeForReview,
  PracticalChallengeState,
} from "../models/practicalChallenges";
import { LoadingStatus } from "../../common/models/loadingStatus";

export interface QueryPracticalChallengesRequest {
  orgId?: string;
  userId?: string;
  states?: PracticalChallengeState[];
  courseUnitId?: string;
}

export const queryPracticalChallenges = createAsyncThunk(
  "learning-practical-challenges/queryPracticalChallenges",
  async (request: QueryPracticalChallengesRequest, { rejectWithValue }) => {
    try {
      const queryPracticalChallengesResponse =
        await queryPracticalChallengesAsync(request);

      return queryPracticalChallengesResponse.data;
    } catch (err) {
      console.warn(err);
      return rejectWithValue({ error: err });
    }
  }
);

export const getPracticalChallengeDetails = createAsyncThunk(
  "learning-practical-challenges/getPracticalChallengeDetails",
  async (practicalChallengeId: string, { rejectWithValue }) => {
    try {
      const getPracticalChallengeDetailsResponse =
        await getPracticalChallengeForReviewAsync(practicalChallengeId);

      return getPracticalChallengeDetailsResponse.data;
    } catch (err) {
      console.warn(err);
      return rejectWithValue({ error: err });
    }
  }
);

interface LearningPracticalChallengesState {
  practicalChallenges: PracticalChallengeForList[];
  practicalChallenge?: PracticalChallengeForReview;
  status: LoadingStatus;
  error: string;
}

const initialState: LearningPracticalChallengesState = {
  practicalChallenges: [],
  status: LoadingStatus.idle,
  error: "",
};

const learningPracticalChallengesSlice = createSlice({
  name: "learningPracticalChallenges",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(queryPracticalChallenges.pending, (state) => {
      return {
        ...state,
        status: LoadingStatus.loading,
      };
    });
    builder.addCase(queryPracticalChallenges.fulfilled, (state, action) => {
      return {
        ...state,
        status: LoadingStatus.succeeded,
        practicalChallenges: action.payload.data,
      };
    });
    builder.addCase(queryPracticalChallenges.rejected, (state) => {
      // @ts-ignore
      return {
        ...state,
        status: LoadingStatus.failed,
        practicalChallenges: [],
        error: "Failed to query practical challenges.",
      };
    });

    builder.addCase(getPracticalChallengeDetails.pending, (state) => {
      return {
        ...state,
        status: LoadingStatus.loading,
      };
    });

    builder.addCase(getPracticalChallengeDetails.fulfilled, (state, action) => {
      return {
        ...state,
        status: LoadingStatus.succeeded,
        practicalChallenge: action.payload.data,
      };
    });

    builder.addCase(getPracticalChallengeDetails.rejected, (state) => {
      // @ts-ignore
      return {
        ...state,
        status: LoadingStatus.failed,
        error: "Failed to get practical challenge details.",
      };
    });
  },
});

export default learningPracticalChallengesSlice.reducer;
