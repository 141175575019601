import { Navigate } from "react-router-dom";
import { useCurrentOrg } from "../hooks/userHooks";
import { GetHomePagePath } from "../utils/routeUtils";
import useAuth from "../hooks/useAuth";

function NavigateToUserHome() {
  const currentOrg = useCurrentOrg();
  const auth = useAuth();

  return <Navigate to={GetHomePagePath(auth.isAuthenticated, currentOrg)} />;
}

export default NavigateToUserHome;
