import { Users } from "react-feather";
import { SidebarItemsType } from "../../types/sidebar";

export const groupsItem = {
  href: "/compliance/groups",
  icon: Users,
  pageTitle: () => "Groups",
  innerNavigationItems: [
    {
      title: "Dashboard",
      to: (_) => "/compliance/groups/dashboard",
    },
    {
      title: "Groups",
      to: (_) => "/compliance/groups/list",
    },
  ],
  children: [
    {
      pageTitle: (groupName: string) => `${groupName ?? ""}`,
      hasBackButton: true,
      backButtonTo: (_) => `/compliance/groups/list`,
      type: "group",
      innerNavigationItems: [
        {
          title: "Dashboard",
          to: (_) => ``,
          disabled: true,
        },
        {
          title: "Group roles",
          to: ({ groupId }) => `/compliance/groups/${groupId}/group-roles`,
        },
        {
          title: "Contractors",
          to: (_) => ``,
          disabled: true,
        },
        {
          title: "Workers",
          to: ({ groupId }) => `/compliance/groups/${groupId}/workers`,
        },
        {
          title: "Worker roles",
          to: ({ groupId }) => `/compliance/groups/${groupId}/worker-roles`,
        },
        {
          title: "Accreditations",
          to: ({ groupId }) => `/compliance/groups/${groupId}/accreditations`,
        },
      ],
      children: [
        {
          pageTitle: (workerName: string) => `${workerName ?? ""}`,
          hasBackButton: true,
          backButtonTo: ({ groupId }) =>
            `/compliance/groups/${groupId}/workers`,
          type: "worker",
          innerNavigationItems: [
            {
              title: "Details",
              to: ({ groupId, workerId }) =>
                `/compliance/groups/${groupId}/workers/${workerId}/details`,
            },
            {
              title: "Worker roles",
              to: ({ groupId, workerId }) =>
                `/compliance/groups/${groupId}/workers/${workerId}/worker-roles`,
            },
            {
              title: "Accreditations",
              to: ({ groupId, workerId }) =>
                `/compliance/groups/${groupId}/workers/${workerId}/accreditations`,
            },
          ],
        },
      ],
    },
  ],
} as SidebarItemsType;
