import React from "react";
import { useNavigate } from "react-router-dom";

import {
  Button,
  Avatar,
  Typography,
  Popper,
  Box,
  Grid,
  IconButton,
  ClickAwayListener,
} from "@mui/material";

import useAuth from "../../hooks/useAuth";
import { useCurrentOrg, useCurrentUser } from "../../hooks/userHooks";
import { X } from "react-feather";

function NavbarUserDropdown() {
  const [anchorMenu, setAnchorMenu] = React.useState<any>(null);
  const [isOpen, setIsOpen] = React.useState(false);
  const navigate = useNavigate();
  const { signOut } = useAuth();
  const user = useCurrentUser();
  const currentOrg = useCurrentOrg();

  const toggleMenu = (event: React.SyntheticEvent) => {
    if (isOpen) {
      closePopper();
      return;
    }
    setAnchorMenu(event.currentTarget);
    setIsOpen(true);
  };

  const closePopper = () => {
    setAnchorMenu(null);
    setIsOpen(false);
  };

  const handleSignOut = async () => {
    await signOut();
    navigate("/");
  };

  return (
    <React.Fragment>
      <Button
        onClick={toggleMenu}
        aria-owns={Boolean(anchorMenu) ? "menu-appbar" : undefined}
        aria-haspopup="true"
      >
        <Grid container>
          <Grid item container xs mr={4} direction={"column"}>
            {user && (
              <Grid item xs display={"flex"} justifyContent={"end"}>
                <Typography fontSize={12} fontWeight={"bold"}>
                  {user.forenames} {user.lastName ?? ""}
                </Typography>
              </Grid>
            )}
            {currentOrg && (
              <Grid item xs display={"flex"} justifyContent={"end"}>
                <Typography fontSize={10}>{currentOrg.name}</Typography>
              </Grid>
            )}
          </Grid>
          <Grid
            item
            xs="auto"
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <Avatar style={{ height: 32, width: 32 }} />
          </Grid>
        </Grid>
      </Button>
      {isOpen && (
        <ClickAwayListener
          onClickAway={() => {
            if (isOpen) {
              closePopper();
            }
          }}
        >
          <Popper
            open={isOpen}
            anchorEl={anchorMenu}
            placement="bottom-end"
            style={{ zIndex: 1200 }}
          >
            <Box width={24} height={10}>
              <Box
                left={240}
                border={"12px solid transparent"}
                borderBottom={"10px solid black"}
                borderTop={0}
                position={"fixed"}
                height={0}
                width={0}
              >
                <Box
                  left={240}
                  top={0.5}
                  border={"12px solid transparent"}
                  borderBottom={"10px solid white"}
                  borderTop={0}
                  position={"fixed"}
                  height={0}
                  width={0}
                />
              </Box>
            </Box>
            <Grid
              container
              height={280}
              width={340}
              border={"1px solid black"}
              style={{ backgroundColor: "white" }}
              alignContent={"start"}
            >
              <Grid
                item
                xs={12}
                height={32}
                justifyContent={"end"}
                display={"flex"}
              >
                <IconButton onClick={closePopper} style={{ padding: 4 }}>
                  <X />
                </IconButton>
              </Grid>
              <Grid
                item
                xs={12}
                display={"flex"}
                justifyContent={"center"}
                alignContent={"center"}
                flexWrap={"wrap"}
              >
                <Avatar style={{ height: 72, width: 72 }} />
              </Grid>
              {user && (
                <Grid
                  item
                  xs={12}
                  display={"flex"}
                  justifyContent={"center"}
                  pt={4}
                >
                  <Typography fontWeight={"bold"} fontSize={16}>
                    {user.forenames} {user.lastName ?? ""}
                  </Typography>
                </Grid>
              )}
              {currentOrg && (
                <Grid item xs={12} display={"flex"} justifyContent={"center"}>
                  <Typography fontSize={12}>{currentOrg.name}</Typography>
                </Grid>
              )}
              <Grid item xs={12} p={"16px 24px"} height={67}>
                <Button variant="contained" fullWidth onClick={handleSignOut}>
                  Sign out
                </Button>
              </Grid>
              <Grid
                container
                item
                xs={12}
                height={50}
                borderTop={"solid 1px black"}
                justifyContent={"center"}
                alignItems={"center"}
              >
                <Grid item xs="auto">
                  <Button
                    variant="text"
                    onClick={() => navigate("/company/members")}
                  >
                    Company
                  </Button>
                </Grid>
                {user && user?.orgs.length > 1 && (
                  <>
                    <Grid item xs="auto">
                      <Box
                        height={4}
                        borderRadius={4}
                        width={4}
                        style={{ backgroundColor: "black" }}
                      />
                    </Grid>
                    <Grid item xs="auto">
                      <Button
                        variant="text"
                        onClick={() =>
                          navigate("/organisation/choose?allowAutoOrgSelect=0")
                        }
                      >
                        Choose org
                      </Button>
                    </Grid>
                  </>
                )}
              </Grid>
            </Grid>
          </Popper>
        </ClickAwayListener>
      )}
    </React.Fragment>
  );
}

export default NavbarUserDropdown;
