import { Table } from "react-feather";
import { SidebarItemsType } from "../types/sidebar";

export const learningSection = [
  {
    href: "/learning/analytics",
    icon: Table,
    pageTitle: () => "Analytics",
    innerNavigationItems: [
      {
        to: (_) => "/learning/analytics/course-summary",
        title: "Course summary",
      },
    ],
  },
  {
    href: "/learning/enrolments",
    icon: Table,
    pageTitle: () => "Enrolments",
    innerNavigationItems: [
      {
        to: (_) => "/learning/enrolments/active",
        title: "Active enrolments",
      },
      {
        to: (_) => "/learning/enrolments/completed",
        title: "Completed enrolments",
      },
    ],
  },
] as SidebarItemsType[];
