import { AxiosPromise } from "axios";
import axios from "../../../../../utils/axios";
import { ApiResponseMeta } from "../../../../common/models/apiResponseMeta";
import { GroupRole } from "../models/groupRoles";
import {
  AssignRequest,
  QueryGroupRolesRequest,
} from "../slices/groupRolesSlice";

type BasicResponse = {
  meta: ApiResponseMeta;
};

type QueryGroupRolesResponse = {
  data: GroupRole[];
  meta: ApiResponseMeta;
};

export async function queryGroupRoles(
  groupId: string,
  request: QueryGroupRolesRequest
): Promise<AxiosPromise<QueryGroupRolesResponse>> {
  return await axios.post(
    `compliance/groups/v1/${groupId}/roles/query`,
    request
  );
}

export async function bulkAssign(
  groupId: string,
  roleId: string,
  request: AssignRequest
): Promise<AxiosPromise<BasicResponse>> {
  return await axios.post(
    `compliance/groups/v1/${groupId}/roles/${roleId}/bulk-assign`,
    request
  );
}

export async function unassign(
  groupId: string,
  roleId: string,
  request: AssignRequest
): Promise<AxiosPromise<BasicResponse>> {
  return await axios.post(
    `compliance/groups/v1/${groupId}/roles/${roleId}/unassign`,
    request
  );
}

type ModifyRolesRequest = {
  orgId: string;
  roleIds: string[];
};

export async function addRoles(
  groupId: string,
  request: ModifyRolesRequest
): Promise<AxiosPromise<BasicResponse>> {
  return await axios.post(`compliance/groups/v1/${groupId}/roles`, request);
}

export async function removeRoles(
  groupId: string,
  request: ModifyRolesRequest
): Promise<AxiosPromise<BasicResponse>> {
  return await axios.post(
    `compliance/groups/v1/${groupId}/roles/bulk-delete`,
    request
  );
}
