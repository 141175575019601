import { lazy } from "react";
import CanViewCapabilityRouteGuard from "../components/guards/CanViewCapabilityRouteGuard";
import VisiblyOSLayout from "../components/layout/VisiblyOSLayout";
import NavigateToUserHome from "../components/NavigateToUserHome";
import { Navigate, RouteObject } from "react-router-dom";

const CourseSummary = lazy(
  () => import("../features/learning/pages/analytics/courseSummary")
);
const CourseUnitSummary = lazy(
  () => import("../features/learning/pages/analytics/courseUnitSummary")
);
const CourseEnrolments = lazy(
  () => import("../features/learning/pages/analytics/enrolmentsForCourse")
);
const ActiveEnrolments = lazy(
  () => import("../features/learning/pages/enrolments/activeEnrolments")
);
const BulkEnrol = lazy(
  () => import("../features/learning/pages/enrolments/bulkEnrol")
);
const EnrolmentDetails = lazy(
  () => import("../features/learning/pages/enrolments/enrolmentDetails")
);

const CompletedEnrolments = lazy(
  () => import("../features/learning/pages/enrolments/completedEnrolments")
);

export const learningRoutes = [
  {
    path: "learning",
    element: (
      <CanViewCapabilityRouteGuard capability="learning">
        <VisiblyOSLayout />
      </CanViewCapabilityRouteGuard>
    ),
    children: [
      {
        path: "",
        element: <NavigateToUserHome />,
      },
      {
        path: "analytics",
        children: [
          {
            path: "",
            element: <Navigate to="/learning/analytics/course-summary" />,
          },
          {
            path: "course-summary",
            element: <CourseSummary />,
          },
          {
            path: "course-unit-summary",
            element: <CourseUnitSummary />,
          },
          {
            path: "course-enrolments",
            element: <CourseEnrolments />,
          },
        ],
      },
      {
        path: "enrolments",
        children: [
          {
            path: "",
            element: <Navigate to="/learning/enrolments/active" />,
          },
          {
            path: "active",
            element: <ActiveEnrolments />,
          },
          {
            path: "completed",
            element: <CompletedEnrolments />,
          },
          {
            path: "bulk-enrol",
            element: <BulkEnrol />,
          },
          {
            path: "details/:id",
            element: <EnrolmentDetails />,
          },
        ],
      },
    ],
  },
] as RouteObject[];
