import { Close } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  Divider,
  Grid,
  Modal as MuiModal,
  Typography,
} from "@mui/material";
import React from "react";

type ModalButtonProps = {
  text: String;
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
  isLoadingButton?: boolean;
  loading?: boolean;
  color?: "error" | undefined;
  disabled?: boolean;
  variant?: "contained" | "outlined";
};

type ModalProps = {
  showModal: boolean;
  headerText: string;
  onClose?: () => void;
  bodyText?: string;
  children?: React.ReactNode;
  buttons?: ModalButtonProps[];
  style?: React.CSSProperties;
  enableClose?: boolean;
};

function Modal({
  showModal,
  headerText,
  onClose,
  bodyText,
  children,
  buttons,
  style,
  enableClose = true,
}: ModalProps) {
  return (
    <MuiModal open={showModal}>
      <Box
        data-cy={`${headerText.replaceAll(" ", "")}Modal`}
        style={{
          position: "relative",
          top: "40%",
          margin: "auto",
          maxWidth: 500,
          minWidth: 300,
          maxHeight: "90%",
          padding: 10,
          borderRadius: 10,
          overflowY: "auto",
          boxShadow: "0px 6px 20px -1px #000",
          backgroundColor: "white",
          overflow: "hidden",
          ...style,
        }}
      >
        <Grid container>
          <Grid item xs={10}>
            <Typography
              variant="h6"
              component="h6"
              style={{ fontWeight: "bold", lineHeight: "2" }}
            >
              {headerText}
            </Typography>
          </Grid>
          {enableClose ? (
            <Grid item xs={2} textAlign="right">
              <Button onClick={onClose} data-cy="closeButton">
                <Close />
              </Button>
            </Grid>
          ) : null}
        </Grid>
        <Divider />
        {bodyText && (
          <Box>
            <Typography style={{ marginTop: 20, marginBottom: 10 }}>
              {bodyText}
            </Typography>
          </Box>
        )}
        {children}
        <Grid style={{ float: "right", marginTop: 10 }}>
          {buttons?.map((button, index) => {
            if (button.isLoadingButton) {
              return (
                <LoadingButton
                  key={`button-${index}`}
                  onClick={button.onClick}
                  color={button.color ?? "primary"}
                  variant={button.variant ?? "contained"}
                  style={{ marginLeft: 10 }}
                  data-cy={`${button.text.replaceAll(" ", "")}Button`}
                  loading={button.loading}
                  disabled={button.disabled}
                >
                  {button.text}
                </LoadingButton>
              );
            }
            return (
              <Button
                key={`button-${index}`}
                onClick={button.onClick}
                color={button.color ?? "primary"}
                variant={button.variant ?? "contained"}
                disabled={button.disabled}
                style={{ marginLeft: 10 }}
                data-cy={`${button.text.replaceAll(" ", "")}Button`}
              >
                {button.text}
              </Button>
            );
          })}
        </Grid>
      </Box>
    </MuiModal>
  );
}

export default Modal;
