import { AxiosResponse } from "axios";
import { ApiResponseMeta } from "../models/apiResponseMeta";
import axios from "../../../utils/axios";
import { DirectWorkforcePermission } from "../models/permissions";

type GetAllResponse = {
  data: DirectWorkforcePermission[];
  meta: ApiResponseMeta;
};

export async function getAll(
  orgId: string
): Promise<AxiosResponse<GetAllResponse>> {
  return await axios.get(`/orgs/v1/${orgId}/permissions`);
}

export type BulkUpdatePermissionsRequest = {
  userId: string;
  orgId: string;
  canView: boolean;
  canEdit: boolean;
  canSetPermissions: boolean;
};

type BulkUpdatePermissionsResponse = {
  meta: ApiResponseMeta;
};

export async function bulkUpdate(
  orgId: string,
  request: BulkUpdatePermissionsRequest[]
): Promise<AxiosResponse<BulkUpdatePermissionsResponse>> {
  return await axios.post(`/orgs/v1/${orgId}/permissions/bulk-update`, request);
}
