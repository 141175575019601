import { createAsyncThunk, createSlice, nanoid } from "@reduxjs/toolkit";
import { LoadingStatus } from "../models/loadingStatus";
import * as accreditationService from "../services/accreditationService";
import * as groupAccreditationService from "../services/groupAccreditationService";
import { AccreditationWithProofs } from "../../compliance/models/accreditation";
import { AxiosError } from "axios";
import { uploadFileToFileStorage } from "../services/s3ClientStorageService";

type ImportAccreditationCsvRequest = {
  orgId: string;
  file: File;
};

export const importAccreditationCsv = createAsyncThunk(
  "accreditation/importAccreditationCsv",
  async (request: ImportAccreditationCsvRequest, { rejectWithValue }) => {
    const importId = nanoid();

    try {
      await uploadFileToFileStorage(
        `public/${request.orgId}/uploads/${importId}.csv`,
        request.file,
        "text/csv"
      );

      await accreditationService.importAccreditations({
        orgId: request.orgId,
        csvImportId: importId,
      });
    } catch (err: any) {
      let error = err?.message ?? "An error occurred";
      if (err instanceof AxiosError && err?.response?.data.meta.errorMessage) {
        error = err.response.data.meta.errorMessage;
      }
      return rejectWithValue({
        error: error,
      });
    }
  }
);

export type QueryAccreditationsRequest = {
  orgId: string;
  statuses?: string[];
  userId?: string;
  groupId?: string;
};

export const queryAccreditations = createAsyncThunk(
  "accreditation/queryAccreditations",
  async (request: QueryAccreditationsRequest, { rejectWithValue }) => {
    try {
      if (request.groupId) {
        return (
          await groupAccreditationService.queryGroupAccreditations(
            request.groupId,
            request
          )
        ).data;
      }
      return (await accreditationService.queryAccreditations(request)).data;
    } catch (err: any) {
      let error = err?.message ?? "An error occurred";
      if (err instanceof AxiosError && err?.response?.data.meta.errorMessage) {
        error = err.response.data.meta.errorMessage;
      }
      return rejectWithValue({
        error: error,
      });
    }
  }
);

export const getAccreditation = createAsyncThunk(
  "accreditation/getAccreditation",
  async (
    request: { id: string; forceRefresh?: boolean; groupId?: string },
    { getState, rejectWithValue }
  ) => {
    try {
      const state = (getState() as { accreditation: AccreditationState })
        .accreditation;

      if (state.accreditation?.id === request.id && !request.forceRefresh) {
        return state.accreditation;
      }
      if (request.groupId) {
        return (
          await groupAccreditationService.getGroupAccreditation(
            request.groupId,
            request.id
          )
        ).data;
      }
      return (await accreditationService.getAccreditation(request.id)).data
        .data;
    } catch (err: any) {
      let error = err?.message ?? "An error occurred";
      if (err instanceof AxiosError && err?.response?.data.meta.errorMessage) {
        error = err.response.data.meta.errorMessage;
      }
      return rejectWithValue({
        error: error,
      });
    }
  }
);

type SubmitProofsRequest = {
  orgId: string;
  proofs: File[];
  accreditationId: string;
};

export const submitProofs = createAsyncThunk(
  "accreditation/submitProofs",
  async (request: SubmitProofsRequest, { rejectWithValue, dispatch }) => {
    try {
      const proofs = await Promise.all(
        request.proofs.map(async (proof) => {
          const mediaKey = `accreditations/${request.orgId}/${nanoid()}_${
            proof.name
          }`;
          await uploadFileToFileStorage(mediaKey, proof, proof.type);

          return {
            type:
              proof.type === "application/pdf"
                ? "pdf"
                : ("photo" as "pdf" | "photo"),
            createdAt: new Date().toISOString(),
            mediaKey: mediaKey,
          };
        })
      );

      await accreditationService.submitProofs(request.accreditationId, {
        proofs: proofs,
        orgId: request.orgId,
      });

      dispatch(
        getAccreditation({ id: request.accreditationId, forceRefresh: true })
      );

      return {
        accreditationId: request.accreditationId,
        proofs: proofs,
      };
    } catch (err: any) {
      let error = err?.message ?? "An error occurred";
      if (err instanceof AxiosError && err?.response?.data.meta) {
        const errorCode = err.response.data.meta.errorCode;
        if (errorCode === "404-COMMON-EntityNotFound") {
          error =
            "Unable to add proofs to accreditation. Please reload and try again";
        }
      }
      return rejectWithValue({
        error: error,
      });
    }
  }
);

export type UpdateExpiryDateRequest = {
  expiresAt: string;
  orgId: string;
  groupId?: string;
};

export const updateExpiryDate = createAsyncThunk(
  "accreditation/updateExpiryDate",
  async (
    request: UpdateExpiryDateRequest & { id: string },
    { rejectWithValue }
  ) => {
    try {
      await accreditationService.updateExpiryDate(request.id, request);

      if (request.groupId) {
        return (
          await groupAccreditationService.getGroupAccreditation(
            request.groupId,
            request.id
          )
        ).data;
      }
      return (await accreditationService.getAccreditation(request.id)).data;
    } catch (err: any) {
      let error = err?.message ?? "An error occurred";
      if (err instanceof AxiosError && err?.response?.data.meta) {
        const errorCode = err.response.data.meta.errorCode;
        if (errorCode === "404-COMMON-EntityNotFound") {
          error =
            "Unable to add proofs to accreditation. Please reload and try again";
        }
      }
      return rejectWithValue({
        error: error,
      });
    }
  }
);

type AccreditationState = {
  status: LoadingStatus;
  submitProofStatus: LoadingStatus;
  getAccreditationStatus: LoadingStatus;
  updateDateStatus: LoadingStatus;
  accreditations: AccreditationWithProofs[];
  accreditation?: AccreditationWithProofs;
  error: string | null;
};

const initialState: AccreditationState = {
  status: LoadingStatus.idle,
  submitProofStatus: LoadingStatus.idle,
  getAccreditationStatus: LoadingStatus.idle,
  updateDateStatus: LoadingStatus.idle,
  accreditations: [],
  error: null,
};

const accreditationSlice = createSlice({
  name: "accreditation",
  initialState,
  reducers: {
    cleanState: () => {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(importAccreditationCsv.pending, (state, action) => {
      return { ...state, status: LoadingStatus.loading };
    });
    builder.addCase(importAccreditationCsv.fulfilled, (state, action) => {
      return {
        ...state,
        status: LoadingStatus.succeeded,
      };
    });
    builder.addCase(importAccreditationCsv.rejected, (state, action) => {
      return {
        ...state,
        status: LoadingStatus.failed,
      };
    });
    builder.addCase(queryAccreditations.pending, (state, action) => {
      return { ...state, status: LoadingStatus.loading };
    });
    builder.addCase(queryAccreditations.fulfilled, (state, action) => {
      return {
        ...state,
        status: LoadingStatus.succeeded,
        accreditations: action.payload.data,
      };
    });
    builder.addCase(queryAccreditations.rejected, (state, action) => {
      return {
        ...state,
        status: LoadingStatus.failed,
      };
    });
    builder.addCase(submitProofs.pending, (state, action) => {
      return { ...state, submitProofStatus: LoadingStatus.loading };
    });
    builder.addCase(submitProofs.fulfilled, (state, action) => {
      return {
        ...state,
        submitProofStatus: LoadingStatus.succeeded,
      };
    });
    builder.addCase(submitProofs.rejected, (state, action) => {
      return {
        ...state,
        submitProofStatus: LoadingStatus.failed,
      };
    });
    builder.addCase(getAccreditation.pending, (state, action) => {
      // In case of forceRefresh we don't change the loading status,
      // this prevent a page rerender and avoids losing filters on the table.
      if (
        state.accreditation?.id === action.meta.arg.id &&
        action.meta.arg.forceRefresh
      ) {
        return { ...state };
      }
      return { ...state, getAccreditationStatus: LoadingStatus.loading };
    });
    builder.addCase(getAccreditation.fulfilled, (state, action) => {
      return {
        ...state,
        getAccreditationStatus: LoadingStatus.succeeded,
        accreditation: action.payload as AccreditationWithProofs,
      };
    });
    builder.addCase(getAccreditation.rejected, (state, action) => {
      return {
        ...state,
        getAccreditationStatus: LoadingStatus.failed,
      };
    });

    builder.addCase(updateExpiryDate.pending, (state, action) => {
      return { ...state, updateDateStatus: LoadingStatus.loading };
    });
    builder.addCase(updateExpiryDate.fulfilled, (state, action) => {
      return {
        ...state,
        updateDateStatus: LoadingStatus.succeeded,
        accreditations: state.accreditations.map((el) => {
          if (el.id === action.meta.arg.id) {
            return action.payload.data;
          }
          return el;
        }),
        accreditation: action.payload.data,
      };
    });
    builder.addCase(updateExpiryDate.rejected, (state, action) => {
      return {
        ...state,
        updateDateStatus: LoadingStatus.failed,
      };
    });
  },
});

export const { cleanState } = accreditationSlice.actions;

export default accreditationSlice.reducer;
