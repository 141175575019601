import { Button, Grid, Menu, MenuItem } from "@mui/material";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { InnerNavigationItem } from "../types/sidebar";
import { useState } from "react";
import { MoreHorizontal } from "react-feather";

type MenuItemProps = {
  title: string;
  action: () => void;
};

type InnerNavigationBarProps = {
  items?: InnerNavigationItem[];
  menuItems?: MenuItemProps[];
};

export function InnerNavigationBar(props: InnerNavigationBarProps) {
  const navigate = useNavigate();
  const location = useLocation();
  const { groupId, workerId, accreditationSpecId, roleId } = useParams();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Grid container spacing={6} ml={0} mt={2}>
      <Grid item xs={11} mb={10}>
        {props.items?.map((el, index) => {
          const url = el.to({
            groupId: groupId,
            workerId: workerId,
            accreditationSpecId: accreditationSpecId,
            roleId: roleId,
          });
          return (
            <Button
              variant={location.pathname === url ? "contained" : "outlined"}
              style={{ marginRight: 10 }}
              onClick={() => {
                navigate(url);
              }}
              disabled={el.disabled}
              key={index}
            >
              {el.title}
            </Button>
          );
        })}
      </Grid>
      {props.menuItems && props.menuItems.length > 0 && (
        <Grid item xs={1}>
          <Button onClick={handleClick} variant="contained">
            <MoreHorizontal />
          </Button>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            sx={{
              "& .MuiPaper-root": {
                border: "1px solid black",
              },
              "& .MuiList-root": {
                padding: 0,
              },
            }}
          >
            {props.menuItems?.map((el, index) => {
              return (
                <MenuItem
                  key={index}
                  onClick={() => {
                    el.action();
                    handleClose();
                  }}
                >
                  {el.title}
                </MenuItem>
              );
            })}
          </Menu>
        </Grid>
      )}
    </Grid>
  );
}

export default InnerNavigationBar;
