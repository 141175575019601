import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import * as companyMembersService from "../services/companyService";
import { LoadingStatus } from "../../common/models/loadingStatus";
import { CompanyMember } from "../models/companyMember";
import { RolePermission } from "../models/rolePermissions";

export type QueryCompanyMembersRequest = {
  orgId: string;
};

export const queryCompanyMembers = createAsyncThunk(
  "companyMembers/query",
  async ({ orgId }: { orgId: string }, { rejectWithValue }) => {
    try {
      return (await companyMembersService.queryCompanyMembers(orgId)).data;
    } catch (err) {
      return rejectWithValue({
        error: "An error occurred",
      });
    }
  }
);

export const getRolePermissions = createAsyncThunk(
  "companyMembers/role-permissions",
  async (_, { rejectWithValue }) => {
    return [
      {
        permission: "One must exist on the company account at all times",
        owner: true,
        admin: false,
        user: false,
      },
      // {
      //   permission: "Can promote an admin to become the owner",
      //   owner: true,
      //   admin: false,
      //   user: false,
      // },
      // {
      //   permission: "Can promote a user to become an admin",
      //   owner: true,
      //   admin: true,
      //   user: false,
      // },
      // {
      //   permission: "Can demote an admin to become a user",
      //   owner: true,
      //   admin: true,
      //   user: false,
      // },
      // {
      //   permission: "Can remove self from account",
      //   owner: false,
      //   admin: true,
      //   user: true,
      // },
      // {
      //   permission: "Can invite new company members",
      //   owner: true,
      //   admin: true,
      //   user: false,
      // },
      // {
      //   permission: "Can remove admins and users",
      //   owner: true,
      //   admin: true,
      //   user: false,
      // },
      {
        permission: "Has access to all product features",
        owner: true,
        admin: true,
        user: true,
      },
      {
        permission:
          "Can view, edit, and set permissions for the direct workforce",
        owner: true,
        admin: true,
        user: false,
      },
      // {
      //   permission:
      //     "Becomes default group manager if group manager leaves or is removed from company account",
      //   owner: true,
      //   admin: false,
      //   user: false,
      // },
    ];
  }
);

type CompanyMembersState = {
  status: LoadingStatus;
  companyMembers: CompanyMember[];
  rolePermissions: RolePermission[];
  error: string | null;
};

const initialState: CompanyMembersState = {
  status: LoadingStatus.idle,
  companyMembers: [],
  rolePermissions: [],
  error: null,
};

const companySlice = createSlice({
  name: "company",
  initialState,
  reducers: {
    cleanState: () => {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(queryCompanyMembers.pending, (state, action) => {
      return { ...state, status: LoadingStatus.loading };
    });
    builder.addCase(queryCompanyMembers.fulfilled, (state, action) => {
      return {
        ...state,
        status: LoadingStatus.succeeded,
        companyMembers: action.payload.data,
      };
    });
    builder.addCase(queryCompanyMembers.rejected, (state, action) => {
      return {
        ...state,
        status: LoadingStatus.failed,
      };
    });
    builder.addCase(getRolePermissions.pending, (state, action) => {
      return { ...state, status: LoadingStatus.loading };
    });
    builder.addCase(getRolePermissions.fulfilled, (state, action) => {
      return {
        ...state,
        status: LoadingStatus.succeeded,
        rolePermissions: action.payload,
      };
    });
    builder.addCase(getRolePermissions.rejected, (state, action) => {
      return {
        ...state,
        status: LoadingStatus.failed,
      };
    });
  },
});

export const { cleanState } = companySlice.actions;
export default companySlice.reducer;
