import { AgGridReact } from "ag-grid-react";
import {
  ClientSideRowModelModule,
  ColDef,
  GetContextMenuItemsParams,
  GridOptions,
  ICellRendererParams,
  MenuItemDef,
  ModuleRegistry,
  ProcessCellForExportParams,
} from "ag-grid-community";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import "../new-ag-grid.css";
import { EmotionJSX } from "@emotion/react/types/jsx-namespace";
import { useCallback, useMemo, useRef, useState } from "react";
import { MoreVert } from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  Grid,
  TextField,
  CardContent,
  Typography,
} from "@mui/material";
import { format } from "date-fns";
import { ColumnsToolPanelModule, MenuModule } from "ag-grid-enterprise";
ModuleRegistry.registerModules([
  ClientSideRowModelModule,
  ColumnsToolPanelModule,
  MenuModule,
]);

type MenuItem = {
  label: string;
  onClick: () => void;
  icon?: string;
  testingClassName: string;
  disabled?: boolean;
  tooltip?: string;
};

type MenuProps<TRowData> = {
  menuItems: (row: TRowData) => MenuItem[];
};

type SearchProps = {
  enabled: boolean;
  disabledColumns?: string[];
};

type SideBarProps = {
  columnSearch?: boolean;
};

type ExportProps = {
  fileTag: string;
};

type AgGridTableProps<TRowData> = {
  data: TRowData[];
  columns: ColDef[];
  menu?: MenuProps<TRowData>;
  gridOptions?: GridOptions<TRowData>;
  dataCyTag: string;
  noRowsOverlay?: () => EmotionJSX.Element;
  search?: SearchProps;
  toolbarElements?: EmotionJSX.Element[];
  disableGrouping?: boolean;
  enableSideBar?: boolean;
  sideBar?: SideBarProps;
  enableExport?: boolean;
  export?: ExportProps;
  enableGrouping?: boolean;
  groupDefaultExpanded?: number;
  masterDetail?: boolean;
  detailCellRenderer?: any;
  autoHeight?: boolean;
  footer?: {
    title: string;
  };
};

function NewAgGridTable<TRowData>(props: AgGridTableProps<TRowData>) {
  const [searchText, setSearchText] = useState<string>("");
  const [filterCount, setFilterCount] = useState<number>(0);
  const [displayedRowCount, setDisplayedRowCount] = useState<number>(
    props.data.length
  );

  const gridRef = useRef<AgGridReact>(null);
  let toolbarElements = props.toolbarElements ?? [];

  if (props.menu && !props.columns.find((el) => el.field === "id")) {
    props.columns.push({
      field: "id",
      headerName: "",
      cellClass: "ag-menu-cell",
      width: 50,
      minWidth: 50,
      maxWidth: 50,
      sortable: false,
      filter: false,
      getQuickFilterText: () => "",
      cellRenderer: (params: ICellRendererParams<TRowData, string>) => {
        if (params.data) {
          var menuItems = props.menu?.menuItems(params.data);
          if (menuItems?.length === 0) {
            return (
              <MoreVert
                data-cy={`ellipsisIcon_${params.value}`}
                style={{ color: "#EEEEEE" }}
              />
            );
          }
        }
        return <MoreVert data-cy={`ellipsisIcon_${params.value}`} />;
      },
      suppressColumnsToolPanel: true,
    } as ColDef);
  }

  if (props.search?.enabled && props.search.disabledColumns) {
    props.search.disabledColumns.forEach((element) => {
      let column = props.columns.find((el) => el.field === element);
      if (column) {
        column.getQuickFilterText = () => "";
      }
    });
  }

  if (props.enableExport) {
    const processCellFunction = (props: ProcessCellForExportParams) => {
      switch (typeof props.value) {
        case "number": {
          var num = props.value as number;
          if (num % 1 !== 0) {
            return num.toFixed(1);
          }
          return num;
        }

        case "string": {
          const dateRegex =
            /\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{0,6}\+\d{2}:\d{2}/g;

          var str = props.value as string;
          if (str?.match(dateRegex)) {
            return format(new Date(props.value), "yyyy-MM-dd");
          }
          return props.value;
        }

        default: {
          return props.value;
        }
      }
    };

    toolbarElements.push(
      <Button
        variant="contained"
        onClick={() => {
          gridRef.current?.api.exportDataAsCsv({
            fileName: `${
              props.export?.fileTag ?? "data_export"
            }_${Date.now()}.csv`,
            allColumns: true,
            processCellCallback: processCellFunction,
          });
        }}
      >
        Export CSV
      </Button>
    );
    toolbarElements.push(
      <Button
        variant="contained"
        onClick={() => {
          gridRef.current?.api.exportDataAsExcel({
            fileName: `${
              props.export?.fileTag ?? "data_export"
            }_${Date.now()}.xlsx`,
            allColumns: true,
            processCellCallback: processCellFunction,
          });
        }}
      >
        Export Excel
      </Button>
    );
  }

  const getContextMenuItems = useCallback(
    (params: GetContextMenuItemsParams): MenuItemDef[] => {
      return (
        props.menu?.menuItems(params.node?.data).map((element) => {
          return {
            name: element.label,
            action: element.onClick,
            icon: element.icon
              ? `<img class="ag-icon" src="/static/img/icons/${element.icon}_icon.png" style="width:18x;height:18px;"/>`
              : null,
            cssClasses: element.testingClassName
              ? ["cy-ag-menu-option-" + element.testingClassName]
              : [],
            disabled: element.disabled,
            tooltip: element.tooltip,
          } as MenuItemDef;
        }) ?? []
      );
    },
    [props.menu]
  );

  const showToolbar = props.search?.enabled || toolbarElements.length > 0;

  const defaultColDef = useMemo<ColDef>(() => {
    return {
      flex: 1,
      minWidth: 150,
      suppressHeaderMenuButton: true,
      comparator: (valueA, valueB, nodeA, nodeB, isDescending) => {
        if (typeof valueA === "string" && typeof valueB === "string") {
          return valueA.localeCompare(valueB);
        }
        return valueA > valueB ? 1 : -1;
      },
    };
  }, []);

  const getFooterDetails = (
    _displayedRowCount: number,
    _filterCount: number
  ) => {
    const footerDetails: any = {};

    if (props.columns[0] && props.columns[0].field) {
      footerDetails[props.columns[0].field] = props.footer!.title;
      const originalCellRenderer = props.columns[0].cellRenderer;
      if (originalCellRenderer !== "agGroupCellRenderer") {
        props.columns[0].cellRenderer = (params: ICellRendererParams) => {
          if (params.node.rowPinned) {
            return (
              <Box
                display={"flex"}
                alignItems={"center"}
                height={"100%"}
                position={"fixed"}
              >
                <Typography fontWeight={"bold"}>{params.value}</Typography>
              </Box>
            );
          } else {
            if (originalCellRenderer) {
              if (typeof originalCellRenderer === "function") {
                return originalCellRenderer(params);
              }
              if (originalCellRenderer === "agGroupCellRenderer") {
                return props.columns[0].cellRendererSelector!(params);
              }
            }
            return params.value;
          }
        };
      } else {
        const originalCellRendererSelector =
          props.columns[0].cellRendererSelector;
        props.columns[0].cellRendererSelector = (
          params: ICellRendererParams
        ) => {
          if (params.node.rowPinned) {
            return {
              component: (params: ICellRendererParams) => (
                <Box
                  display={"flex"}
                  alignItems={"center"}
                  height={"100%"}
                  position={"fixed"}
                >
                  <Typography fontWeight={"bold"}>{params.value}</Typography>
                </Box>
              ),
            };
          } else {
            if (originalCellRendererSelector) {
              return originalCellRendererSelector(params);
            }
            return params.value;
          }
        };
      }
    }
    if (props.columns && props.columns[props.columns.length - 1]) {
      const rowText = `${_displayedRowCount === 1 ? "Row" : "Rows"}: ${
        _filterCount > 0
          ? `${_displayedRowCount} of ${props.data.length}`
          : _displayedRowCount
      }`;
      footerDetails[
        props.columns[props.columns.length - 1].field ??
          props.columns[props.columns.length - 1].colId!
      ] = [rowText, `Filters applied: ${_filterCount ?? 0}`];
      const originalCellRenderer =
        props.columns[props.columns.length - 1].cellRenderer;
      props.columns[props.columns.length - 1].cellRenderer = (
        params: ICellRendererParams
      ) => {
        if (params.node.rowPinned) {
          return (
            <Box width={300} position={"absolute"} right={15}>
              <Box style={{ float: "left" }}>
                <Typography fontWeight={"bold"}>
                  {
                    params.data[
                      params.colDef?.field ?? params.colDef!.colId!
                    ][0]
                  }
                </Typography>
              </Box>
              <Box style={{ float: "right" }}>
                <Typography fontWeight={"bold"}>
                  {
                    params.data[
                      params.colDef?.field ?? params.colDef!.colId!
                    ][1]
                  }
                </Typography>
              </Box>
            </Box>
          );
        } else {
          if (originalCellRenderer) {
            if (typeof originalCellRenderer === "function") {
              return originalCellRenderer(params);
            }
            if (originalCellRenderer === "agGroupCellRenderer") {
              return props.columns[props.columns.length - 1]
                .cellRendererSelector!(params);
            }
          }
          return params.value;
        }
      };
    }

    props.columns.forEach((column, index) => {
      if (index !== 0 && index !== props.columns.length - 1) {
        const originalCellRenderer = column.cellRenderer;
        column.cellRenderer = (params: ICellRendererParams) => {
          if (params.node.rowPinned) {
            return null;
          } else {
            if (originalCellRenderer) {
              if (typeof originalCellRenderer === "function") {
                return originalCellRenderer(params);
              }
              if (originalCellRenderer === "agGroupCellRenderer") {
                return column.cellRendererSelector!(params);
              }
            }
            return params.value;
          }
        };
      }
    });

    return footerDetails;
  };

  return (
    <>
      <Card style={{ marginBottom: 16, height: "100%", overflow: "visible" }}>
        <CardContent style={{ padding: 16, height: "100%" }}>
          {showToolbar ? (
            <Box style={{ marginBottom: 16 }}>
              <Grid container>
                <Grid item xs={9}>
                  {toolbarElements.length > 0 ? (
                    <Grid container flexDirection="row">
                      {toolbarElements.map((element, index) => {
                        return (
                          <Grid
                            item
                            m={2}
                            display="flex"
                            alignItems="center"
                            alignContent="center"
                            key={index}
                          >
                            {element}
                          </Grid>
                        );
                      })}
                    </Grid>
                  ) : null}
                </Grid>
                <Grid item xs={3}>
                  {props.search?.enabled ? (
                    <TextField
                      id="search-text-box"
                      type="text"
                      label="Search"
                      onChange={(event) => setSearchText(event.target.value)}
                      style={{ height: 52 }}
                      fullWidth
                    />
                  ) : null}
                </Grid>
              </Grid>
            </Box>
          ) : null}
          <Box
            className="ag-theme-quartz"
            style={{
              height: showToolbar ? "calc(100% - 68px)" : "100%",
              fontSize: 14,
              fontWeight: "normal",
              width: "100%",
            }}
          >
            <AgGridReact
              ref={gridRef}
              rowData={props.data}
              columnDefs={props.columns}
              paginationAutoPageSize
              defaultColDef={defaultColDef}
              noRowsOverlayComponent={props.noRowsOverlay}
              getContextMenuItems={getContextMenuItems}
              suppressContextMenu={true}
              quickFilterText={searchText}
              onCellClicked={(params: any) => {
                if (props.menu) {
                  if (params.column.colDef.field === "id") {
                    params.api.menuService.contextMenuFactory?.showMenu(
                      params.node,
                      params.column,
                      params.value,
                      params.event
                    );
                  }
                }
              }}
              columnMenu="new"
              isGroupOpenByDefault={() => true}
              gridOptions={{
                ...props.gridOptions,
                pinnedBottomRowData: props.footer
                  ? [getFooterDetails(displayedRowCount, filterCount)]
                  : undefined,
                onModelUpdated: props.footer
                  ? (params) => {
                      const filterModel = params.api.getFilterModel();
                      setFilterCount(Object.keys(filterModel).length);
                      const rowCount = params.api.getDisplayedRowCount();
                      setDisplayedRowCount(params.api.getDisplayedRowCount());
                      params.api.setGridOption("pinnedBottomRowData", [
                        getFooterDetails(
                          rowCount,
                          Object.keys(filterModel).length
                        ),
                      ]);
                    }
                  : undefined,
                suppressCellFocus: true,
                detailRowAutoHeight: true,
              }}
              domLayout={props.autoHeight ? "autoHeight" : undefined}
              tooltipShowDelay={0}
              groupDefaultExpanded={props.groupDefaultExpanded}
              masterDetail={props.masterDetail}
              detailCellRenderer={props.detailCellRenderer}
            ></AgGridReact>
          </Box>
        </CardContent>
      </Card>
    </>
  );
}

export default NewAgGridTable;
