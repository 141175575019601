import { useSelector } from "react-redux";
import useAuth from "./useAuth";
import { selectUser } from "../features/common/slices/userSlice";

export const useCurrentUser = () => {
  return useSelector(selectUser);
};

export const useCurrentOrg = () => {
  const currentUser = useCurrentUser();
  const { currentOrgId } = useAuth();

  if (!currentUser || !currentOrgId) {
    return undefined;
  }

  return currentUser?.orgs.find((element) => element.id === currentOrgId);
};

export const useDirectWorkforcePermissions = () => {
  const currentOrg = useCurrentOrg();
  return (
    currentOrg?.directWorkforcePermissions ?? {
      canEdit: false,
      canSetPermissions: false,
      canView: false,
    }
  );
};
