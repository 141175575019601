import { Fragment } from "react";
import CanViewCapabilityNavItemGuard from "../components/sidebar/navItemGuards/CanViewNavItemGuard";
import VisiblyAdminNavItemGuard from "../components/sidebar/navItemGuards/VisiblyAdminNavItemGuard";
import { SidebarSectionType } from "../types/sidebar";
import { checkInsSection } from "./checkInsSection";
import { companySection } from "./companySection";
import { complianceSection } from "./compliance/complianceSection";
import { internalAdminSection } from "./internalAdminSection";
import { librariesSection } from "./libraries/librariesSection";
import { learningSection } from "./learningSection";

export const navSections = [
  {
    title: "Compliance",
    pages: complianceSection,
    wrapperElement: (key, children) => {
      return (
        <CanViewCapabilityNavItemGuard key={key} capability="accreditations">
          {children}
        </CanViewCapabilityNavItemGuard>
      );
    },
  },
  {
    title: "Libraries",
    pages: librariesSection,
    wrapperElement: (key, children) => {
      return (
        <CanViewCapabilityNavItemGuard key={key} capability="accreditations">
          {children}
        </CanViewCapabilityNavItemGuard>
      );
    },
  },
  {
    title: "Certification",
    pages: learningSection,
    wrapperElement: (key, children) => {
      return (
        <CanViewCapabilityNavItemGuard key={key} capability="learning">
          {children}
        </CanViewCapabilityNavItemGuard>
      );
    },
  },
  {
    title: "Duty of care",
    pages: checkInsSection,
    wrapperElement: (key, children) => {
      return (
        <CanViewCapabilityNavItemGuard key={key} capability="checkIns">
          {children}
        </CanViewCapabilityNavItemGuard>
      );
    },
  },
  {
    title: "Internal admin",
    pages: internalAdminSection,
    wrapperElement: (key, children) => {
      return (
        <VisiblyAdminNavItemGuard key={key}>
          {children}
        </VisiblyAdminNavItemGuard>
      );
    },
  },
  {
    title: "Company",
    pages: companySection,
    wrapperElement: (key, children) => {
      return <Fragment key={key}></Fragment>;
    },
  },
] as SidebarSectionType[];

export default navSections;
