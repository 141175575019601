import { Avatar, Box, Grid, Typography } from "@mui/material";
import {
  CellEditingStoppedEvent,
  ColDef,
  EditableCallbackParams,
  ICellRendererParams,
} from "ag-grid-community";
import { useSnackbar } from "notistack";
import { useState, useEffect, useMemo, useCallback } from "react";
import { LoadingStatus } from "../../../features/common/models/loadingStatus";
import {
  BulkUpdatePermissionsRequest,
  bulkUpdate,
} from "../../../features/common/services/permissionsService";
import ErrorComponent from "../../../features/compliance/components/ErrorComponent";
import useAppDispatch from "../../../hooks/useAppDispatch";
import useAppSelector from "../../../hooks/useAppSelector";
import NewAgGridTable from "../../NewAgGridTable";
import {
  getAll,
  cleanState,
} from "../../../features/common/slices/permissionsSlice";
import Modal from "../../Modal";
import Loader from "../../Loader";
import useAuth from "../../../hooks/useAuth";

type PermissionsModalProps = {
  show: boolean;
  onClose: () => void;
};

export default function PermissionsModal({
  show,
  onClose,
}: PermissionsModalProps) {
  const dispatch = useAppDispatch();
  const { currentOrgId } = useAuth();
  const [updates, setUpdates] = useState<BulkUpdatePermissionsRequest[]>([]);
  const [isSaving, setIsSaving] = useState(false);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const permissions = useAppSelector(
    (state) => state.permissions.directWorkforcePermissions
  );
  const permissionsStatus = useAppSelector((state) => state.permissions.status);

  useEffect(() => {
    if (currentOrgId) {
      dispatch(getAll(currentOrgId));
    }
    return () => {
      setUpdates([]);
      setIsSaving(false);
      dispatch(cleanState());
    };
  }, [dispatch, currentOrgId]);

  const data = useMemo(() => {
    return permissions.map((permission) => ({
      ...permission,
      name: `${permission.firstNames} ${permission.lastName ?? ""}`,
    }));
  }, [permissions]);

  const mapRole = useCallback((role: string) => {
    switch (role) {
      case "owner":
        return "Company owner";
      case "admin":
        return "Company admin";
      case "user":
        return "Company user";
      default:
        return role;
    }
  }, []);

  const handleCellEditingStopped = useCallback(
    (event: CellEditingStoppedEvent) => {
      if (!event.data) return;

      if (event.colDef.field === "canEdit") {
        if (event.newValue === true) {
          event.node.setDataValue("canView", true);
        }
        event.api.updateGridOptions({
          columnDefs: event.api.getColumnDefs()!.map((col: ColDef) => {
            if (col.field === "canView") {
              return {
                ...col,
                editable: (params: EditableCallbackParams) => {
                  if (
                    params.data?.role === "owner" ||
                    params.data?.role === "admin" ||
                    params.data?.canEdit
                  ) {
                    return false;
                  }
                  return true;
                },
              };
            }
            return col;
          }),
        });
      }

      setUpdates((prev) => {
        if (!event.data) return prev;
        const existing = prev.find(
          (update) => update.userId === event.data!.userId
        );
        if (existing) {
          const original = permissions.find(
            (p) => p.userId === existing.userId
          );
          if (
            original?.canEdit === event.data.canEdit &&
            original?.canView === event.data.canView &&
            original?.canSetPermissions === event.data.canSetPermissions
          ) {
            return prev.filter((update) => update.userId !== existing.userId);
          }
          existing.canView = event.data.canView;
          existing.canEdit = event.data.canEdit;
          existing.canSetPermissions = event.data.canSetPermissions;
          return prev;
        } else {
          return [
            ...prev,
            {
              userId: event.data.userId,
              orgId: event.data.orgId,
              canView: event.data.canView,
              canEdit: event.data.canEdit,
              canSetPermissions: event.data.canSetPermissions,
            },
          ];
        }
      });
    },
    [permissions]
  );

  const handleSaveChanges = useCallback(async () => {
    try {
      setIsSaving(true);
      if (currentOrgId) {
        await bulkUpdate(currentOrgId, updates);
      }
      onClose();

      const snackbarKey = enqueueSnackbar("Permissions changes saved", {
        variant: "success",
        onClick: () => closeSnackbar(snackbarKey),
      });
    } catch (error) {
      const snackbarKey = enqueueSnackbar(
        "Error saving permissions, please refresh and try again",
        {
          variant: "error",
          onClick: () => closeSnackbar(snackbarKey),
        }
      );
    } finally {
      setIsSaving(false);
    }
  }, [updates, onClose, closeSnackbar, currentOrgId, enqueueSnackbar]);

  const columns = useMemo(() => {
    return [
      {
        field: "name",
        headerName: "Company member",
        sortable: true,
        flex: 1,
        filter: true,
        sort: "asc",
        comparator: (
          a: string,
          b: string,
          nodeA: any,
          nodeB: any,
          isDescending: boolean
        ) => {
          const roleOrder = ["owner", "admin", "user"];
          const roleA = nodeA.data.role;
          const roleB = nodeB.data.role;
          if (roleOrder.indexOf(roleA) !== roleOrder.indexOf(roleB)) {
            return roleOrder.indexOf(roleA) - roleOrder.indexOf(roleB);
          } else {
            return a.localeCompare(b);
          }
        },
        cellRenderer: (params: ICellRendererParams) => {
          const text = !params.value ? "-" : params.value;
          return (
            <>
              <Box marginRight={4}>
                <Avatar
                  style={{
                    height: 30,
                    width: 30,
                    border: "1px black solid",
                    backgroundColor: "#DFDFDF",
                  }}
                >
                  <Typography fontSize={12} color={"black"} fontWeight={700}>
                    {text
                      .split(" ")
                      .map((name: string) => name[0])
                      .join("")
                      .substring(0, 2)}
                  </Typography>
                </Avatar>
              </Box>
              <Box>
                <Typography>{text}</Typography>
                <Typography fontSize={10} color={"black"} fontWeight={500}>
                  {mapRole(params.data?.role ?? "")}
                </Typography>
              </Box>
            </>
          );
        },
      },
      {
        field: "canView",
        headerName: "Can view",
        showDisabledCheckboxes: true,
        flex: 1,
        cellEditor: "agCheckboxCellEditor",
        editable: (params: EditableCallbackParams) => {
          if (
            params.data?.role === "owner" ||
            params.data?.role === "admin" ||
            params.data?.canEdit
          ) {
            return false;
          }
          return true;
        },
      },
      {
        field: "canEdit",
        headerName: "Can edit",
        flex: 1,
        cellEditor: "agCheckboxCellEditor",
        editable: (params: EditableCallbackParams) => {
          if (params.data?.role === "owner" || params.data?.role === "admin") {
            return false;
          }
          return true;
        },
      },
      {
        field: "canSetPermissions",
        headerName: "Can set permissions",
        flex: 1,
        cellEditor: "agCheckboxCellEditor",
        editable: false,
      },
    ] as ColDef[];
  }, [mapRole]);

  const renderTable = useCallback(() => {
    return (
      <NewAgGridTable
        data={data}
        columns={columns}
        gridOptions={{
          onCellEditingStopped: handleCellEditingStopped,
        }}
        dataCyTag="permissionsTable"
      />
    );
  }, [data, columns, handleCellEditingStopped]);

  return (
    <Modal
      showModal={show}
      headerText={"Direct workforce permissions"}
      onClose={onClose}
      enableClose={false}
      buttons={[
        {
          text: "Cancel & close window",
          onClick: onClose,
          variant: "outlined",
        },
        {
          text: "Save changes & close window",
          onClick: handleSaveChanges,
          variant: "contained",
          isLoadingButton: true,
          loading: isSaving,
          disabled: updates.length === 0,
        },
      ]}
      style={{
        width: 900,
        maxWidth: 900,
        height: 600,
        top: "calc(50% - 300px)",
      }}
    >
      <Grid container>
        <Grid item xs={12} height={500}>
          {(permissionsStatus === LoadingStatus.idle ||
            (permissionsStatus === LoadingStatus.loading &&
              permissions.length === 0)) && <Loader />}
          {permissionsStatus === LoadingStatus.failed && <ErrorComponent />}
          {(permissionsStatus === LoadingStatus.succeeded ||
            permissions.length > 0) &&
            renderTable()}
        </Grid>
        <Grid item xs={12}></Grid>
      </Grid>
    </Modal>
  );
}
