import { Fragment } from "react";
import { useDirectWorkforcePermissions } from "../../../hooks/userHooks";

type DirectWorkforceViewGuardProps = {
  children: JSX.Element;
};

export default function DirectWorkforceViewGuard(
  props: DirectWorkforceViewGuardProps
) {
  const { canView } = useDirectWorkforcePermissions();
  if (!canView) {
    return null;
  }
  return <Fragment>{props.children}</Fragment>;
}
