import { AxiosPromise } from "axios";
import { ApiResponseMeta } from "../models/apiResponseMeta";
import axios from "../../../utils/axios";
import { AccreditationSpec } from "../models/accreditationSpec";
import { QueryRequest } from "../slices/accreditationSpecsSlice";

type QueryResponse = {
  data: AccreditationSpec[];
  meta: ApiResponseMeta;
};

export async function queryAccreditationSpecs(
  request: QueryRequest
): Promise<AxiosPromise<QueryResponse>> {
  return await axios.post(`accreditations/specs/v1/query`, request);
}

type GetResponse = {
  data: AccreditationSpec;
  meta: ApiResponseMeta;
};

export async function getAccreditationSpec(
  id: number
): Promise<AxiosPromise<GetResponse>> {
  return await axios.get(`accreditations/specs/v1/${id}`);
}

type CreateResponse = {
  meta: ApiResponseMeta;
};

export async function createAccreditationSpec(
  request: Omit<AccreditationSpec, "id" | "createdAt">
): Promise<AxiosPromise<CreateResponse>> {
  return await axios.post(`accreditations/specs/v1/`, request);
}

export async function updateAccreditationSpec(
  id: number,
  request: Omit<AccreditationSpec, "id" | "createdAt">
): Promise<AxiosPromise<CreateResponse>> {
  return await axios.put(`accreditations/specs/v1/${id}`, request);
}
