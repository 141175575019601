import { Navigate, RouteObject, useParams } from "react-router-dom";
import VisiblyOSLayout from "../components/layout/VisiblyOSLayout";
import { lazy, ReactElement, useContext, useEffect } from "react";
import CanViewCapabilityRouteGuard from "../components/guards/CanViewCapabilityRouteGuard";
import useAppDispatch from "../hooks/useAppDispatch";
import { PageHeaderContext } from "../contexts/PageHeaderContext";
import { getRole } from "../features/common/slices/accreditationRolesSlice";

const RolesLibrary = lazy(() => import("../features/libraries/pages/roles"));
const RoleDetails = lazy(
  () => import("../features/libraries/pages/roles/RoleDetails")
);
const RoleRequiredAccreditations = lazy(
  () =>
    import(
      "../features/libraries/pages/roles/RoleDetails/RequiredAccreditations"
    )
);

export const roleLibraryRoutes = [
  {
    path: "libraries",
    element: (
      <CanViewCapabilityRouteGuard capability="accreditations">
        <VisiblyOSLayout />
      </CanViewCapabilityRouteGuard>
    ),
    children: [
      {
        path: "roles",
        element: <RolesLibrary />,
      },
      {
        path: "roles/:roleId",
        children: [
          {
            path: "",
            element: <NavigateToRoleHome />,
          },
          {
            path: "details",
            element: (
              <RoleWrapper>
                <RoleDetails />
              </RoleWrapper>
            ),
          },
          {
            path: "accreditations",
            element: (
              <RoleWrapper>
                <RoleRequiredAccreditations />
              </RoleWrapper>
            ),
          },
        ],
      },
    ],
  },
] as RouteObject[];

type RoleWrapperProps = {
  children: ReactElement;
};

function RoleWrapper(props: RoleWrapperProps) {
  const dispatch = useAppDispatch();
  const { roleId } = useParams();
  const { role, setRole } = useContext(PageHeaderContext);

  useEffect(() => {
    if (roleId && (!role || roleId !== role.id)) {
      dispatch(getRole(roleId))
        .unwrap()
        .then((value) => {
          setRole!(value.data);
        });
    }
  }, [role, roleId, setRole, dispatch]);

  return <>{role && roleId === role.id ? props.children : null}</>;
}

function NavigateToRoleHome() {
  const { roleId } = useParams();

  return <Navigate to={`/libraries/roles/${roleId}/accreditations`} />;
}
