import { AxiosPromise } from "axios";
import axios from "../../../utils/axios";
import { CompanyMember } from "../models/companyMember";
import { ApiResponseMeta } from "../../common/models/apiResponseMeta";

type QueryCompanyMembersResponse = {
  meta: ApiResponseMeta;
  data: CompanyMember[];
};

export async function queryCompanyMembers(
  orgId: string
): Promise<AxiosPromise<QueryCompanyMembersResponse>> {
  return await axios.get(`orgs/v1/${orgId}/members/company-members`);
}
